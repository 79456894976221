import React from 'react'
import { useText } from 'texts'

const List = ({ items, sectionKey }) => {
  const { t } = useText()

  return <ul>
    {items.map((item) => <li dangerouslySetInnerHTML={{
          __html: t(`privacyPolicy.sections.${sectionKey}.body.list.${item}`)
        }}/>)}
  </ul>
}

export default List
