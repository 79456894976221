import React from 'react'
import styled from 'styled-components'

import Screens from 'theme/screens'
import { useText } from 'texts'

import Section from "./Section"

const introduction = [{
  tKey: 'introduction',
  bodyKey: 'body',
  body: [
    'p1',
    'p2',
    'p3',
    'p4',
    'p5',
    'p6',
    {
      tKey: 'list',
      list: [
        'el1',
        'el2',
        'el3',
        'el4',
        'el5',
        'el6',
        'el7'
      ]
    }
  ] 
}]

const sections = [
  {
    tKey: 's1',
    titleKey: 'title',
    bodyKey: 'body',
    body: [
      'p1',
      'p2',
      {
        tKey: 'list',
        list: [
          'el1',
          'el2',
          'el3',
          'el4',
          'el5'
        ]
      }
    ]
  },
  {
    tKey: 's2',
    titleKey: 'title',
    bodyKey: 'body',
    body: [
      'p1',
      'p2',
      'p3',
      'p4',
      'p5',
      'p6'
    ]
  },
  {
    tKey: 's3',
    titleKey: 'title',
    bodyKey: 'body',
    body: [
      'p1',
      'p2',
      'p3'
    ]
  },
  {
    tKey: 's4',
    titleKey: 'title',
    bodyKey: 'body',
    body: [
      'p1'
    ]
  },
  {
    tKey: 's5',
    titleKey: 'title',
    bodyKey: 'body',
    body: [
      'p1',
      'p2'
    ]
  },
  {
    tKey: 's6',
    titleKey: 'title',
    bodyKey: 'body',
    body: [
      'p1',
      {
        tKey: 'list',
        list: [
          'el1',
          'el2',
          'el3',
          'el4',
          'el5',
          'el6',
          'el7'
        ]
      },
      'p2'
    ]
  },
  {
    tKey: 's7',
    titleKey: 'title',
    bodyKey: 'body',
    body: [
      'p1',
      'p2',
      'p3'
    ]
  },
  {
    tKey: 's8',
    titleKey: 'title',
    bodyKey: 'body',
    body: [
      'p1',
      {
        tKey: 'list',
        list: [
          'el1',
          'el2',
        ]
      },
      'p2',
      'p3'
    ]
  },
 
]

const buildSections = (sections) => (
  sections.map((section, index) => (
    <Section section={section} index={index}/>
  ))
)

const StyledPrivacyPolicy = styled.div`
  width: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 20%;
  padding-right: 20%;
  z-index: 1;

  @media (max-width: ${Screens.md}) {
    min-width: auto;
  }

  @media (max-width: ${Screens.sm}) {
    position: relative;
    max-width: 100%;
    padding-right: 20%;
    align-items: center;
    text-align: center;
  }
`

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 20%;
  padding-right: 20%;
`

const SectionContainer = styled.div`
  width: 100%;
`
const TermsOfUse = () => {
  const { t } = useText()

  return (
    <StyledPrivacyPolicy>
      <TextContainer>
        <h2 className="text-primary-100">{t('privacyPolicy.hero.title')}</h2>
      </TextContainer>
      <SectionContainer>
        {buildSections([...introduction, ...sections])}
      </SectionContainer>
      <FooterContainer>
        <span>{t('privacyPolicy.footer.name')}</span>
        <span>{t('privacyPolicy.footer.title')}</span>
        <span>{t('privacyPolicy.footer.updated')}</span>
        <h3>{t('privacyPolicy.footer.farewell')}</h3>
      </FooterContainer>
    </StyledPrivacyPolicy>
  )
}

export default TermsOfUse
