import React from 'react'
import styled from 'styled-components'

import Colors from 'theme/colors'
import { useText } from 'texts'

import List from "./List"

const StyledDefinition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 20%;
  padding-right: 20%;
  scroll-margin-top: 75px;
  a {
    font-weight: bold;
    color: #0086ff;
    text-decoration: none;
  }
  background-color: ${({ highlight }) => {
    return highlight ? Colors.GRAY_100 : Colors.NEUTRAL_100
  }}
`

const StyledParagraph = styled.p`
    margin-top: 12px;
    margin-bottom: 12px;
`

const Section = ({ section, index }) => {
  const { t } = useText()

  const sectionKey = section.tKey

  const hasTitle = section?.titleKey
  const hasBody = section.bodyKey

  return <StyledDefinition highlight={index % 2 != 0}>
    {hasTitle && <h3 className="text-primary-100">{t(`privacyPolicy.sections.${sectionKey}.title`)}</h3>}
    {hasBody && section.body.map((item) => {
      if(item?.tKey == 'list'){
        return <><List items={item.list} sectionKey={sectionKey}/><br/></>
      }
      else{
        return <><StyledParagraph dangerouslySetInnerHTML={{
          __html: t(`privacyPolicy.sections.${sectionKey}.body.${item}`)
        }}/><br/></>
      }
    })}
  </StyledDefinition>
}

export default Section
